.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
}

.App-logo {
  height: 8vmin;
  width: auto;
  margin: 2rem 2rem 0;
}

.App-header {
  display: flex;
  flex-direction: column;
}

.App-link {
  color: #09d3ac;
}

.content{
  flex:1;
  font-size: 0.7em;
  font-family: "Broadsheet", Arial, sans-serif;
  display: flex;
  flex-direction: column;
  padding: 1em 3em 3em;
  min-height: 300px;
  >p{
    max-width: 800px;
    align-self: center;
    width: 100%;
  }

}
.bottle{
  width: auto;
margin: 2rem;
}

.carousel {
  background: #000;

  img {
    max-height: 500px;
    max-width: 100%;
  }
}


nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0.7em 0;

  a {
    font-family: "Broadsheet", Arial, sans-serif;
    font-size: .8em;
    padding: 0 1em;
    line-height: 2em;
    &:hover{
      background: #000;
      color: #FFF;
      cursor: pointer;
    }
  }
}

footer{
  font-size: 0.5em;
  background: #000000;
  padding: 2em;
  color: #CCC;
}
